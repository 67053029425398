<template>
  <div>
    <navbar-top left="back"
                middle="counter"
                @cl="back">
      <p class="text-xl text-white">1/1</p>
    </navbar-top>

    <div class="fixed inset-0 py-14 overflow-y-auto"
         style="-webkit-overflow-scrolling: touch;">
      <div v-if="dataLoaded"
           class="wrapper mb-6 px-3 py-2">
        <flash-side side="question" :blocks="card.question" />
        <hr class="my-4">
        <flash-side v-if="showAnswer"
                    side="answer"
                    :blocks="card.answer" />
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>

    <navbar-bottom state="recalling"
                   @rotate="rotate" />

    <keypress key-event="keyup" :multiple-keys="keyboard.rotate" @success="rotate" />
    <keypress key-event="keyup" :multiple-keys="keyboard.back" @success="back" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Keypress from 'vue-keypress';
import NavbarTop from '@/components/NavbarTop';
import NavbarBottom from '@/components/NavbarBottom';
import MenuSpinner from '@/components/MenuSpinner.vue';
import FlashSide from '@/components/FlashSide.vue';

export default {
  components: { NavbarTop, NavbarBottom, MenuSpinner, FlashSide, Keypress },
  props: { cardId: String },
  data () {
    return {
      dataLoaded: false,
      showAnswer: false,
      keyboard: {
        rotate: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
          { keyCode: 32, modifiers: [], preventDefault: false }, // SPACE
        ],
        back: [
          { keyCode: 27, modifiers: [], preventDefault: false }, // ESC
        ],
      },
    };
  },
  computed: {
    ...mapState({
      card: state => state.deck.card,
    }),
  },
  created () {
    this.$store.commit('flashes/initStore'); // to clear any testing decisions left from solving
    this.$store.dispatch('deck/fetchCard', this.cardId)
        .then(() => this.dataLoaded = true);
  },
  methods: {
    rotate () { return this.showAnswer = !this.showAnswer; },
    back () { return this.$router.push(`/deck/${this.card.deck}`); },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: block;
  margin: auto;

  @media (max-width: 639px) {
    width: 100%;
  }

  @media (min-width: 640px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
}
</style>
